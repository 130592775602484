export default {
    bill_00001: "Order center",
    bill_00002: "Transactions",
    bill_00003: "Status",
    bill_00004: "All",
    bill_00005: "Charging",
    bill_00006: "To be paid",
    bill_00007: "Paid/Third-party payment",
    bill_00008: "Abnormal",
    bill_00009: "User type",
    bill_00010: "App user",
    bill_00011: "Online card",
    bill_00012: "Deposit card",
    bill_00013: "Private card",
    bill_00014: "Admin",
    bill_00015: "Fleet",
    bill_00016: "third-party user",
    bill_00017: "Transaction List",
    bill_00018: "Export Transactions",
    bill_00019: "Transactions ID",
    bill_00020: "Order user",
    bill_00021: "Please enter the user name",
    bill_00022: "Site equipment",
    bill_00023: "Reset",
    bill_00024: "Search",
    bill_00025: "Revenue({0})",
    bill_00026: "Order amount({0})",
    bill_00027: "Electricity fee amount ({0})",
    bill_00028: "Service fee amount({0})",
    bill_00029: "Idle fee amount({0})",
    bill_00030: "Electricity Sold ({0})",
    bill_00031: "Avg Charging Duration (min)",
    bill_00032: "Total Sessions",
    bill_00033: "Charger",
    bill_00034: "kWh({0})",
    bill_00035: "Duration",
    bill_00036: "day",
    bill_00037: "hour",
    bill_00038: "minute",
    bill_00039: "Details",
    bill_00040: "App user",
    bill_00041: "Paid",
    bill_00042: "Third-party paid",
    bill_00043: "Charging",
    bill_00044: "Billing",
    bill_00045: "To be paid",
    bill_00046: "paused",
    bill_00047: "Finished but still plugged",
    bill_00048: "Billing idle fee",
    bill_00049: "Cancelled",
    bill_00050: "Created",
    bill_00051: "Abnormal",
    bill_00052: "Start Time",
    bill_00053: "End Time",
    bill_00054: "Fleet",
    bill_00055: "Current bill amount",
    bill_00056: "Actual payment",
    bill_00057: "Date from",
    bill_00058: "Date to",
    bill_00059: "Today",
    bill_00060: "A week ago",
    bill_00061: "15 days ago",
    bill_00062: "Reminder",
    bill_00063: "This will download Excel files of transactions, continue?",
    bill_00064: "Confirm",
    bill_00065: "Cancel",
    bill_00066: "Data is in preparation, please wait patiently. Please do not close or refresh the page during this period...",
    bill_00067: "User nickname",
    bill_00068: "Telephone",
    bill_00069: "Card number",
    bill_00070: "License Plate Number",
    bill_00071: "Charging amount is less than 1 kWh",
    bill_00072: "Charging duration is too long",
    bill_00073: "Device malfunction",
    bill_00074: "Car malfunction",
    bill_00075: "Double connector transaction",
    bill_00076: "Occupation fee",
    bill_00077: "yesterday",
    bill_00078: "30 days ago",
    bill_00079: "Prepaidkarte",
    bill_00080: "Start/Stopp-Karte",
    bill_00081: "ID-Karte",
    bill_00082: "Administrator",
    bill_00083: "Backup data",
    bill_00084: "POS user",
    bill_00085: "Monetary unit",
    bill_00086: "Orders for the user",
    bill_00087: "Nickname",
    bill_00088: "Mobile",
    bill_00089: "CardID",
    bill_00090: "LicenseNo",
    bill_00091: "Plug and charge",
    bill_00101: "Charging amount is more than 1000 kWh",
    bill_00102: "Abnormal charging capacity”",
    bill_00103: "Modified order amount({0})",
    bill_00104: "Discount amount({0})",
}