<template>
  <!-- 推送资费 -->
  <el-dialog :title="$t('common_61134')" :visible.sync="pushRatesVisible" width="880px" custom-class='push-dialog' :modal-append-to-body="false" :close-on-click-modal="false" :lock-scroll="false" :before-close="pushCustomClose">
    <div class="wrap">
      <div class="page_1" v-show="page==1">
        <h3 class="rate_name">{{$t('common_61057')}}：{{title}}</h3>
        <h3>{{retry?$t('common_61031'):$t('common_61058')}}：</h3>
        <div class="tips" v-if="retry">{{$t('common_61144')}}</div>
        <el-row class="query_tabs">
          <el-col :span="24">
            <el-tabs v-model="active">
              <el-tab-pane :label="$t('common_61032')+$t('common_61037',[publicTableData.length||0])" name="public">
                <!--
                <div class="operatiop_wrap">
                  <el-select multiple collapse-tags reserve-keyword :filter-method="dataFilter" class="w226 no_show_tags" clearable size="small" v-model="publicSite" filterable :placeholder="$t('common_61059')" @change="selectAllChange(1)" @clear="clearEvent(1)" @click.native="eqNoClick">
                    <el-checkbox v-show="allCheckFlag" v-model="publicChecked" :indeterminate="Boolean(publicIsIndeterminate)" size="medium" @change="selectAll(1)" style="display: block;padding-left:20px;">{{$t('common_61147')}}</el-checkbox>
                    <el-option v-for="item,i in siteList" :disabled="tableData.some(val=>val.siteId===item.id&&(val.isF||val.isS))" :key="`${item.id}_site_${i}`" :label="item.title" :value="item.id">
                    </el-option>
                  </el-select>
                  <el-button type="primary" @click="add_tableData(1)" size="medium">{{$t('common_00212')}}</el-button>
                  <div class="all_bind">

                    <el-checkbox v-if="slowChecked1" v-model="slowChecked1" :label="$t('common_61148')" disabled></el-checkbox>
                    <el-popover v-else placement="bottom" width="200" v-model="isShowPopS1">
                      <p style="margin-bottom:10px;"><i class="el-icon-info" style="color:#f90;"></i>{{$t('common_61150')}}</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="isShowPopS1 = false">{{$t('common_00215')}}</el-button>
                        <el-button type="primary" size="mini" @click="checkedChange('slow')">{{$t('common_00234')}}</el-button>
                      </div>
                      <el-checkbox slot="reference" v-model="slowChecked1" :label="$t('common_61148')" @change="allCheck(1,'slow')"></el-checkbox>
                    </el-popover>

                    <el-checkbox v-if="fastChecked1" v-model="fastChecked1" :label="$t('common_61149')" disabled></el-checkbox>
                    <el-popover v-else placement="bottom" width="200" v-model="isShowPopF1">
                      <p style="margin-bottom:10px;"><i class="el-icon-info" style="color:#f90;"></i>{{$t('common_61151')}}</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="isShowPopF1 = false">{{$t('common_00215')}}</el-button>
                        <el-button type="primary" size="mini" @click="checkedChange('fast')">{{$t('common_00234')}}</el-button>
                      </div>
                      <el-checkbox slot="reference" v-model="fastChecked1" :label="$t('common_61149')" @change="allCheck(1,'fast')"></el-checkbox>
                    </el-popover>
                  </div>
                
                </div>
                -->
              </el-tab-pane>
              <el-tab-pane v-if="ratesType==0 && groupTableData.length" :label="$t('common_61033')+$t('common_61037',[groupTableData.length||0])" name="group">
                <!--
                <div class="operatiop_wrap">
                  <el-select class="sele" clearable size="small" v-model="groupId" filterable :placeholder="$t('common_61071')" @change="groupIdChange">
                    <el-option v-for="item in groupOptions" :key="item.id" :label="item.title" :value="item.id">
                    </el-option>
                  </el-select>
                  <el-select multiple collapse-tags reserve-keyword :filter-method="dataFilter" class="w226 no_show_tags" clearable size="small" v-model="groupSiteId" filterable :placeholder="$t('common_61059')" @change="selectAllChange(2)" @clear="clearEvent(2)" @click.native="eqNoClick">
                    <el-checkbox v-show="allCheckFlag" v-model="groupChecked" :indeterminate="Boolean(groupIsIndeterminate)" size="medium" @change="selectAll(2)" style="display: block;padding-left:20px;">{{$t('common_61147')}}</el-checkbox>
                    <el-option :disabled="tableData.some(val=>val.siteId===item.id&&val.userGroupId===groupId&&(val.isF||val.isS))" v-for="item in siteList" :key="`${item.id}_group`" :label="item.title" :value="item.id">
                    </el-option>
                  </el-select>
                  <el-button type="primary" @click="add_tableData(2)" size="medium">{{$t('common_00212')}}</el-button>
                  <div class="all_bind">

                    <el-checkbox v-if="slowChecked2" v-model="slowChecked2" :label="$t('common_61148')" disabled></el-checkbox>
                    <el-popover v-else placement="bottom" width="200" v-model="isShowPopS2">
                      <p style="margin-bottom:10px;"><i class="el-icon-info" style="color:#f90;"></i>{{$t('common_61150')}}</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="isShowPopS2 = false">{{$t('common_00215')}}</el-button>
                        <el-button type="primary" size="mini" @click="checkedChange('slow')">{{$t('common_00234')}}</el-button>
                      </div>
                      <el-checkbox slot="reference" v-model="slowChecked2" :label="$t('common_61148')" @change="allCheck(2,'slow')"></el-checkbox>
                    </el-popover>

                    <el-checkbox v-if="fastChecked2" v-model="fastChecked2" :label="$t('common_61149')" disabled></el-checkbox>
                    <el-popover v-else placement="bottom" width="200" v-model="isShowPopF2">
                      <p style="margin-bottom:10px;"><i class="el-icon-info" style="color:#f90;"></i>{{$t('common_61151')}}</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="isShowPopF2 = false">{{$t('common_00215')}}</el-button>
                        <el-button type="primary" size="mini" @click="checkedChange('fast')">{{$t('common_00234')}}</el-button>
                      </div>
                      <el-checkbox slot="reference" v-model="fastChecked2" :label="$t('common_61149')" @change="allCheck(2,'fast')"></el-checkbox>
                    </el-popover>
                  </div>
                </div>
                -->
              </el-tab-pane>
              <el-tab-pane v-if="ratesType==0 && product == 'cn'&& trafficTableData.length" :label="$t('common_61034')+$t('common_61037',[trafficTableData.length||0])" name="traffic">
                <!--
                <div class="operatiop_wrap">
                  <el-select class="sele" clearable size="small" v-model="trafficId" filterable :placeholder="$t('common_61072')" @change="trafficIdChange">
                    <el-option v-for="item in trafficOptions" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                  <el-select multiple collapse-tags reserve-keyword :filter-method="dataFilter" class="w226 no_show_tags" clearable size="small" v-model="trafficSiteId" filterable :placeholder="$t('common_61059')" @change="selectAllChange(3)" @clear="clearEvent(3)" @click.native="eqNoClick">
                    <el-checkbox v-show="allCheckFlag" v-model="trafficChecked" :indeterminate="Boolean(trafficIsIndeterminate)" size="medium" @change="selectAll(3)" style="display: block;padding-left:20px;">{{$t('common_61147')}}</el-checkbox>
                    <el-option :disabled="tableData.some(val=>val.siteId===item.id&&val.outSideOwnerId===trafficId&&(val.isF||val.isS))" v-for="item in siteList" :key="`${item.id}_traffic`" :label="item.title" :value="item.id">
                    </el-option>
                  </el-select>
                  <el-button type="primary" @click="add_tableData(3)" size="medium">{{$t('common_00212')}}</el-button>
                  <div class="all_bind">

                    <el-checkbox v-if="slowChecked3" v-model="slowChecked3" :label="$t('common_61148')" disabled></el-checkbox>
                    <el-popover v-else placement="bottom" width="200" v-model="isShowPopS3">
                      <p style="margin-bottom:10px;"><i class="el-icon-info" style="color:#f90;"></i>{{$t('common_61150')}}</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="isShowPopS3 = false">{{$t('common_00215')}}</el-button>
                        <el-button type="primary" size="mini" @click="checkedChange('slow')">{{$t('common_00234')}}</el-button>
                      </div>
                      <el-checkbox slot="reference" v-model="slowChecked3" :label="$t('common_61148')" @change="allCheck(3,'slow')"></el-checkbox>
                    </el-popover>
  
                    <el-checkbox v-if="fastChecked3" v-model="fastChecked3" :label="$t('common_61149')" disabled></el-checkbox>
                    <el-popover v-else placement="bottom" width="200" v-model="isShowPopF3">
                      <p style="margin-bottom:10px;"><i class="el-icon-info" style="color:#f90;"></i>{{$t('common_61151')}}</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="isShowPopF3 = false">{{$t('common_00215')}}</el-button>
                        <el-button type="primary" size="mini" @click="checkedChange('fast')">{{$t('common_00234')}}</el-button>
                      </div>
                      <el-checkbox slot="reference" v-model="fastChecked3" :label="$t('common_61149')" @change="allCheck(3,'fast')"></el-checkbox>
                    </el-popover>
                  </div>
                </div>
                -->
              </el-tab-pane>
              <el-tab-pane label="" name=""></el-tab-pane>
            </el-tabs>
          </el-col>
        </el-row>

        <div class="table_wrap" v-loading="loading" :element-loading-text="$t('common_61154')">
          <el-table :data="tableData" style="width: 100%" key="public_table">
            <el-table-column prop="title" label="">
              <template slot-scope="scope">
                <span class="title_wrap">
                  <span v-if="active=='group'">{{scope.row.userGroupTitle}} - </span>
                  <span v-if="active=='traffic'">{{scope.row.outSideOwneTitle}} - </span>
                  {{scope.row.siteName}}
                </span>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="">
              <template slot-scope="scope">
                <div :class="['push_content',{'de_style':lang=='de'}]">
                  <!-- 没有交流桩 -->
                  <span v-if="!scope.row.slowChargers" >{{$t('common_45004')}}：{{scope.row.slowChargers}}</span>
                  <!-- 原来绑定好的 -->
                  <span v-else-if="scope.row.isSsuccess==1">{{$t('common_45004')}}：{{scope.row.slowChargers}}</span>
                  <span v-else>{{$t('common_45004')}}：{{scope.row.slowChargers}}</span>

                  <!-- 新添加的站点需要绑定的交流桩 -->
                  <!-- <el-popover v-else placement="bottom" width="200" v-model="scope.row.isShowPopS">
                    <p style="margin-bottom:10px;"><i class="el-icon-info" style="color:#f90;"></i>{{scope.row.popTips}}</p>
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" type="text" @click="scope.row.isShowPopS = false">{{$t('common_00215')}}</el-button>
                      <el-button type="primary" size="mini" @click="bindingEvent(scope.row,scope.$index)">{{$t('common_00234')}}</el-button>
                    </div>
                    <el-checkbox slot="reference" v-model="scope.row.isS" @change="checkboxRetry('s',scope.row,scope.$index)" :disabled="scope.row.isS">{{$t('common_45004')}}：{{scope.row.slowChargers}}</el-checkbox>
                  </el-popover> -->
                  <!-- 已绑定 -->
                  <span class="status text-09B59D" v-if="scope.row.isSsuccess==1">{{$t('common_61068')}}</span>
                  <!-- 重试 -->
                  <!-- <span class="status retry_bind text-FA545E" v-if="scope.row.isSsuccess==2" @click="bindingEvent(scope.row,scope.$index)">{{$t('common_61069')}}</span> -->
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="">
              <template slot-scope="scope">
                <div :class="['push_content',{'de_style':lang=='de'}]">
                  <span v-if="!scope.row.fastChargers">{{$t('common_45003')}}：{{scope.row.fastChargers}}</span>
                  <span v-else-if="scope.row.isFsuccess==1">{{$t('common_45003')}}：{{scope.row.fastChargers}}</span>
                  <span v-else>{{$t('common_45003')}}：{{scope.row.fastChargers}}</span>
                  <!-- <el-popover v-else placement="bottom" width="200" v-model="scope.row.isShowPopF">
                    <p style="margin-bottom:10px;"><i class="el-icon-info" style="color:#f90;"></i>{{scope.row.popTips}}</p>
                    <div style="text-align: right; margin: 0">
                      <el-button size="mini" type="text" @click="scope.row.isShowPopF = false">{{$t('common_00215')}}</el-button>
                      <el-button type="primary" size="mini" @click="bindingEvent(scope.row,scope.$index)">{{$t('common_00234')}}</el-button>
                    </div>
                    <el-checkbox slot="reference" v-model="scope.row.isF" @change="checkboxRetry('f',scope.row,scope.$index)" :disabled="scope.row.isF">{{$t('common_45003')}}：{{scope.row.fastChargers}}</el-checkbox>
                  </el-popover> -->
                  <!-- 已绑定 -->
                  <span class="status text-09B59D" v-if="scope.row.isFsuccess==1">{{$t('common_61068')}}</span>
                  <!-- 重试 -->
                  <span class="status retry_bind text-FA545E" v-if="scope.row.isFsuccess==2" @click="bindingEvent(scope.row,scope.$index)">{{$t('common_61069')}}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="" width="120">
              <template slot-scope="scope">
                <div class="operatiop_content text-FA545E" v-if="!scope.row.isF&&!scope.row.isS" @click="removeTableItem(scope.$index,scope.row)">{{$t('common_00214')}}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="page_2" v-show="page==2">
        <!-- 推送结果 -->
        <push-res :energy="isPushEnergy" :retry="retry" :pushData="pushData" :ratesType="ratesType" :pushVisible="pushRatesVisible" :immediatePush="true" />
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="pushCustomClose" size="medium">{{page==1?$t('common_00215'):$t('common_00303')}}</el-button>
      <el-button type="primary" v-show="page==1" @click="sureBtn" size="medium">{{retry?$t('common_61134'):$t('common_00234')}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { requestUrl, ajax } from "@/assets/utils/network";
// import pushRes from "./pushResDialog.vue";
import pushRes from "@/components/ratesPushRes.vue";
export default {
  props: ["pushRatesVisible", "allOptions", "allData", "isPushEnergy", "retry"],
  components: {
    pushRes,
  },
  data() {
    return {
      product:'',
      loading: false, // 批量绑定loading
      lang: "",
      page: 1,
      active: "public",
      title: "", // 资费名称
      id: "", // 资费 id
      ratesType: 0, // 资费类型--充电--储能
      siteList: [], // 站点下拉框--展示options
      allCheckFlag: true, // 搜索时不展示全选按钮
      // ****公共资费
      publicCheckList: [],
      publicSite: [],
      publicOptions: [],
      publicTableData: [],
      publicChecked: false,
      slowChecked1: false, // 推送全部交流桩
      isShowPopS1: false,
      fastChecked1: false, // 推送全部直流桩
      isShowPopF1: false,
      // ****团体资费
      groupId: "",
      groupSiteId: [],
      groupOptions: [],
      groupSiteOptions: [],
      groupTableData: [],
      groupChecked: false,
      slowChecked2: false, // 推送全部交流桩
      isShowPopS2: false,
      fastChecked2: false, // 推送全部直流桩
      isShowPopF2: false,
      // ****第三方流量
      trafficId: "",
      trafficSiteId: [],
      trafficOptions: [],
      trafficSiteOptions: [],
      trafficTableData: [],
      trafficChecked: false,
      slowChecked3: false, // 推送全部交流桩
      isShowPopS3: false,
      fastChecked3: false, // 推送全部直流桩
      isShowPopF3: false,
      // ****推送结果
      pushData: [],
    };
  },
  watch: {
    allData: {
      handler(val) {
        let { rangData, ratesContent } = val;
        if (!rangData || !ratesContent) return;
        this.title = ratesContent.title;
        this.id = ratesContent.id;
        this.ratesType = ratesContent.type;
        if (ratesContent.type == 1) {
          this.active = "public";
        }
        this.publicTableData = this.formatterSiteList(rangData.default);
        this.publicSite = this.publicTableData.map((it) => it.siteId);
        this.groupTableData = this.formatterSiteList(rangData.userGroup);
        this.trafficTableData = this.formatterSiteList(rangData.outsideOwner);
      },
      deep: true,
    },
    allOptions: {
      handler(val) {
        if (this.ratesType != 1) {
          this.publicOptions =
            JSON.parse(JSON.stringify(val.publicOptions)) || [];
        }
        if (this.ratesType == 1) {
          this.publicOptions =
            JSON.parse(JSON.stringify(val.energySitepublicOptions)) || [];
        }
        this.groupOptions = JSON.parse(JSON.stringify(val.groupOptions)) || [];
        this.trafficOptions =
          JSON.parse(JSON.stringify(val.outsideOptions)) || [];
      },
      deep: true,
    },
  },
  created() {
    // this.lang = window.localStorage.lang;
    this.product = window.product_area;
  },
  computed: {
    publicIsIndeterminate() {
      return (
        this.publicSite.length &&
        this.publicSite.length < this.publicOptions.length
      );
    },
    groupIsIndeterminate() {
      return (
        this.groupSiteId.length &&
        this.groupSiteId.length < this.groupSiteOptions.length
      );
    },
    trafficIsIndeterminate() {
      return (
        this.trafficSiteId.length &&
        this.trafficSiteId.length < this.trafficSiteOptions.length
      );
    },
    // ****表格
    tableData() {
      this.eqNoClick();
      let res = [];
      switch (this.active) {
        case "public":
          res = this.publicTableData;
          break;
        case "group":
          res = this.groupTableData;
          break;
        case "traffic":
          res = this.trafficTableData;
          break;
        default:
          break;
      }
      return res;
    },
  },
  methods: {
    // 单独选中某个站点时改变全选复选框状态
    selectAllChange(type) {
      switch (type) {
        case 1:
          this.publicChecked =
            this.publicSite.length === this.publicOptions.length;
          break;
        case 2:
          this.groupChecked =
            this.groupSiteId.length === this.groupSiteOptions.length;
          break;
        case 3:
          this.trafficChecked =
            this.trafficSiteId.length === this.trafficSiteOptions.length;
          break;
        default:
          break;
      }
    },
    // 下拉框搜索事件
    dataFilter(val) {
      const map = {
        public: this.publicOptions,
        group: this.groupSiteOptions,
        traffic: this.trafficSiteOptions,
      };
      if (!val) {
        this.allCheckFlag = true;
        this.siteList = map[this.active];
      }
      if (val) {
        this.allCheckFlag = false;
        this.siteList = map[this.active].filter(
          (it) => it.title.indexOf(val) != -1
        );
      }
    },
    // 每次点击下拉框展示所有数据
    eqNoClick() {
      const map = {
        public: this.publicOptions,
        group: this.groupSiteOptions,
        traffic: this.trafficSiteOptions,
      };
      this.allCheckFlag = true;
      this.siteList = map[this.active];
    },
    // 下拉框中的全选复选框
    selectAll(type) {
      const sourceMap = {
        1: {
          sites: this.publicSite,
          checked: this.publicChecked,
          options: this.publicOptions,
          tableData: this.publicTableData,
        },
        2: {
          sites: this.groupSiteId,
          checked: this.groupChecked,
          options: this.groupSiteOptions,
          tableData: this.groupTableData,
        },
        3: {
          sites: this.trafficSiteId,
          checked: this.trafficChecked,
          options: this.trafficSiteOptions,
          tableData: this.trafficTableData,
        },
      };
      const temp = sourceMap[type];
      temp.sites.splice(0, temp.sites.length);
      if (temp.checked) {
        temp.options.forEach((item) => {
          temp.sites.push(item.id);
        });
      } else {
        temp.tableData.forEach((it) => {
          let flag = true;
          if (type == 2) flag = this.groupId == it.userGroupId;
          if (type == 3) flag = this.trafficId == it.outSideOwnerId;
          if ((it.isF || it.isS) && flag) temp.sites.push(it.siteId);
        });
      }
    },
    // 清空
    clearEvent(val) {
      switch (val) {
        case 1:
          this.publicTableData.forEach((it) => {
            if (it.isF || it.isS) this.publicSite.push(it.siteId);
          });
          break;
        case 2:
          this.groupTableData.forEach((it) => {
            if ((it.isF || it.isS) && this.groupId == it.userGroupId)
              this.groupSiteId.push(it.siteId);
          });
          break;
        case 3:
          this.trafficTableData.forEach((it) => {
            if ((it.isF || it.isS) && this.trafficId == it.outSideOwnerId)
              this.trafficSiteId.push(it.siteId);
          });
          break;
      }
    },
    // 公共资费添加
    add_tableData(type) {
      let pItem = [];
      /* 
      type:
        1.公共资费
        2.团体
        3.第三方
       */
      const sourceMap = {
        1: {
          options: this.publicOptions,
          siteIds: this.publicSite,
          tableData: this.publicTableData,
        },
        2: {
          options: this.groupSiteOptions,
          siteIds: this.groupSiteId,
          tableData: this.groupTableData,
        },
        3: {
          options: this.trafficSiteOptions,
          siteIds: this.trafficSiteId,
          tableData: this.trafficTableData,
        },
      };
      const fn = (source, p) => {
        // 表格中已有的保持不变 表格中不存在的新选中的添加到最前
        source.options.forEach((item) => {
          let flag1 = source.siteIds.includes(item.id); // 下拉框中是否选择了
          let flag2 = false; // 表格里是否已经存在
          if (type == 1)
            flag2 = !source.tableData.some((it) => it.siteId == item.id);
          if (type == 2)
            flag2 = !source.tableData.some(
              (it) => it.siteId == item.id && it.userGroupId == this.groupId
            );
          if (type == 3)
            flag2 = !source.tableData.some(
              (it) =>
                it.siteId == item.id && it.outSideOwnerId == this.trafficId
            );
          if (flag1 && flag2) {
            let obj = {
              isF: false,
              isS: false,
              isSsuccess: 0,
              isFsuccess: 0,
              siteId: item.id,
              siteName: item.title,
              fastChargers: item.fastChargers,
              slowChargers: item.slowChargers,
              energyStorageChargers: item.energyStorageChargers,
              feePolicyFast: this.id,
              feePolicySlow: this.id,
              isShowPopF: false,
              isShowPopS: false,
              popTips: "",
            };
            if (type == 2) {
              obj.userGroupTitle = p[0].title;
              obj.userGroupId = p[0].id;
            }
            if (type == 3) {
              obj.outSideOwneTitle = p[0].name;
              obj.outSideOwnerId = p[0].id;
            }
            source.tableData.unshift(obj);
          }
        });
        // 部分选中中取消了某些选中则从表格中删除
        for (let i = 0; i < source.tableData.length; i++) {
          let temp = source.tableData[i];
          let flag = true;
          if (type == 2) flag = temp.userGroupId == this.groupId;
          if (type == 3) flag = temp.outSideOwnerId == this.trafficId;
          // 下拉框里没有选中且该站点没有被绑定交流/直流桩---团体/第三方需加上是否为同一团体/第三方判断
          if (
            !source.siteIds.includes(temp.siteId) &&
            !temp.isF &&
            !temp.isS &&
            flag
          ) {
            source.tableData.splice(i, 1);
            i--;
          }
        }
      };
      if (type == 2)
        pItem = this.groupOptions.filter((it) => it.id == this.groupId);
      if (type == 3)
        pItem = this.trafficOptions.filter((it) => it.id == this.trafficId);
      fn(sourceMap[type], pItem);
    },
    // 团体资费-团体变化
    groupIdChange(val) {
      if (!val) {
        this.groupSiteOptions = [];
        return;
      }
      ajax({
        type: "GET",
        url: `${requestUrl}/site?userGroupId=${this.groupId}`,
        dataType: "json",
        success: (response) => {
          let res = JSON.parse(response).result || "";
          if (!res) return;
          this.groupSiteOptions = res;
          // 回显已选中的数据
          this.groupSiteId = [];
          let temp = res.map((it) => it.id);
          this.groupTableData.forEach((it) => {
            if (temp.includes(it.siteId) && it.userGroupId == this.groupId) {
              this.groupSiteId.push(it.siteId);
            }
          });
          this.selectAllChange(2);
        },
      });
    },
    // 第三方资费--第三方变化
    trafficIdChange(val) {
      if (!val) {
        this.trafficSiteOptions = [];
        return;
      }
      // let id = this.trafficId.split(":")[1];
      ajax({
        type: "GET",
        url: `${requestUrl}/site?outsideOwnerId=${this.trafficId}`,
        dataType: "json",
        success: (response) => {
          let res = JSON.parse(response).result || "";
          if (!res) return;
          this.trafficSiteOptions = res;
          // 回显已选中的数据
          this.trafficSiteId = [];
          let temp = res.map((it) => it.id);
          this.trafficTableData.forEach((it) => {
            if (
              temp.includes(it.siteId) &&
              it.outSideOwnerId == this.trafficId
            ) {
              this.trafficSiteId.push(it.siteId);
            }
          });
          this.selectAllChange(3);
        },
      });
    },
    // 单独绑定站点-确认浮窗
    checkboxRetry(val, row, index) {
      if (val == "f" && row.isFsuccess == 1) return;
      if (val == "s" && row.isSsuccess == 1) return;
      let type = val == "f" ? this.$t("common_61060") : this.$t("common_61061");
      let tipsType = "";
      let params = {
        feePolicyId: this.id,
        siteId: row.siteId,
        applyScope: val == "f" ? "fast" : "slow",
      };
      if (this.active == "public") {
        params.applyId = 0;
        params.applyType = "userGroup";
        tipsType = this.$t("common_61062");
      }
      if (this.active == "group") {
        params.applyType = "userGroup";
        params.applyId = row.userGroupId;
        tipsType = `${row.userGroupTitle}${this.$t("common_61063")}`;
      }
      if (this.active == "traffic") {
        params.applyType = "outsideOwner";
        params.applyId = row.outSideOwnerId;
        tipsType = `${row.outSideOwneTitle}${this.$t("common_61064")}`;
      }
      if (val == "f") {
        row.isF = false;
        row.isShowPopF = true;
      } else {
        row.isS = false;
        row.isShowPopS = true;
      }
      row.popTips = `${this.$t("common_61065")}${row.siteName}${this.$t(
        "common_61066"
      )} - ${tipsType}${this.$t("common_61067")}${type}`;
      row.params = params;
      this.$set(this.tableData, index, row);
    },
    // 绑定站点
    bindingEvent(row, index) {
      return new Promise((resolve, reject) => {
        let params = row.params;
        ajax({
          type: "POST",
          url: `${requestUrl}/dealer/feepolicy/bind`,
          dataType: "json",
          data: JSON.stringify(params),
          success: (response) => {
            let res = JSON.parse(response);
            if (res.error) {
              // #9672
              if (res.error?.code=='NOT_SUPPORT'&&res.error?.reason?.type=='mutiFeePolicy') {
                this.$message({
                  type: "error",
                  duration: 5000,
                  message: this.$t('common_80007',{type:(res.error.reason.details||[]).join(',')}),
                });
              }
              if (params.applyScope == "fast") {
                row.isFsuccess = 2;
                row.isF = false;
                row.isShowPopF = false;
              }
              if (params.applyScope == "slow") {
                row.isSsuccess = 2;
                row.isS = false;
                row.isShowPopS = false;
              }
              this.$set(this.tableData, index, row);
              reject("fail");
            } else {
              if (params.applyScope == "fast") {
                row.isFsuccess = 1;
                row.isF = true;
                row.isShowPopF = false;
              }
              if (params.applyScope == "slow") {
                row.isSsuccess = 1;
                row.isS = true;
                row.isShowPopS = false;
              }
              this.$set(this.tableData, index, row);
              resolve("success");
            }
          },
        });
      });
    },
    // 批量绑定
    checkedChange(val) {
      const typeMap = {
        public: 1,
        group: 2,
        traffic: 3,
      };
      if (val == "slow") {
        this[`isShowPopS${typeMap[this.active]}`] = false;
      }
      if (val == "fast") {
        this[`isShowPopF${typeMap[this.active]}`] = false;
      }
      const sourceMap = {
        public: this.publicTableData,
        group: this.groupTableData,
        traffic: this.trafficTableData,
      };
      const temp = [];
      sourceMap[this.active].forEach((it, i) => {
        if (
          (val == "slow" && it.slowChargers && !it.isS) ||
          (val == "fast" && it.fastChargers && !it.isF)
        ) {
          let params = {
            feePolicyId: this.id,
            siteId: it.siteId,
            applyScope: val,
          };
          if (this.active == "public") {
            params.applyId = 0;
            params.applyType = "userGroup";
          }
          if (this.active == "group") {
            params.applyType = "userGroup";
            params.applyId = it.userGroupId;
          }
          if (this.active == "traffic") {
            params.applyType = "outsideOwner";
            params.applyId = it.outSideOwnerId;
          }
          it.params = params;
          temp.push(this.bindingEvent(it, i));
        }
      });
      if (temp.length) this.loading = true;
      Promise.all(temp)
        .then((res) => {
          console.log(res, "all then");
          this.$message.success(this.$t("common_61152"));
          if (val == "slow") {
            this[`slowChecked${typeMap[this.active]}`] = true;
          }
          if (val == "fast") {
            this[`fastChecked${typeMap[this.active]}`] = true;
          }
        })
        .catch((err) => {
          console.log(err, "all catch");
          this.$message.error(this.$t("common_61153"));
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 推送全部桩-确认浮窗
    allCheck(type, val) {
      if (val == "slow") {
        this[`slowChecked${type}`] = false;
        this[`isShowPopS${type}`] = true;
      }
      if (val == "fast") {
        this[`fastChecked${type}`] = false;
        this[`isShowPopF${type}`] = true;
      }
    },
    // 删除
    removeTableItem(index, row) {
      if (row.isF || row.isS) return;
      const sourceMap = {
        public: {
          sites: this.publicSite,
          tableData: this.publicTableData,
          type: 1,
        },
        group: {
          sites: this.groupSiteId,
          tableData: this.groupTableData,
          type: 2,
        },
        traffic: {
          sites: this.trafficSiteId,
          tableData: this.trafficTableData,
          type: 3,
        },
      };
      const temp = sourceMap[this.active];
      let flag = true;
      if (this.active == "group") flag = row.userGroupId == this.groupId;
      if (this.active == "traffic") flag = row.outSideOwnerId == this.trafficId;
      if (flag)
        temp.sites.splice(
          temp.sites.findIndex((it) => it == row.siteId),
          1
        );
      temp.tableData.splice(index, 1);
      this.selectAllChange(temp.type);
    },
    pushCustomClose() {
      this.slowChecked1 = false;
      this.fastChecked1 = false;
      this.slowChecked2 = false;
      this.fastChecked2 = false;
      this.slowChecked3 = false;
      this.fastChecked3 = false;
      this.publicSite = [];
      this.groupId = "";
      this.groupSiteId = [];
      this.groupSiteOptions = [];
      this.trafficId = "";
      this.trafficSiteId = [];
      this.trafficSiteOptions = [];
      this.$emit("pushCustomClose", false);
      this.page = 1;
    },
    // 获取已绑定站点
    formatterSiteList(arr) {
      let res = arr.map((item) => {
        let obj = Object.assign({}, item);
        obj.isFsuccess = 0;
        obj.isSsuccess = 0;
        obj.isF = false;
        obj.isS = false;
        obj.isShowPopF = false;
        obj.isShowPopS = false;
        obj.popTips = "";
        if (obj.feePolicyFast == this.id) {
          obj.isFsuccess = 1; // 原来绑定好的 1:已绑定-推送，0 新加的--未绑定-不推送， 2 新加的--绑定失败--重试--不推送
          obj.isF = true; // 原来绑定好的 是否推送
        }
        if (obj.feePolicySlow == this.id) {
          obj.isSsuccess = 1; // 原来绑定好的
          obj.isS = true; // 原来绑定好的
        }
        return obj;
      });
      return res;
    },
    // 开始推送
    sureBtn() {
      let temp = [
        ...this.publicTableData,
        ...this.groupTableData,
        ...this.trafficTableData,
      ];
      let tag = temp.every((item) => !item.isS && !item.isF);
      if (!temp.length || tag) {
        this.$message({
          type: "error",
          offset: 70,
          message: this.$t("common_61070"),
        });
        return;
      }
      this.pushData = temp;
      this.page = 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  .no_show_tags {
    /deep/.el-select__tags > span {
      display: none;
    }
  }
  .text-but {
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
  }
  .text-5278E2 {
    @extend .text-but;
    color: #5278e2;
  }
  .text-09B59D {
    @extend .text-but;
    color: #09b59d;
  }
  .text-FA545E {
    @extend .text-but;
    color: #fa545e;
  }
  .page_1 {
    .rate_name {
      margin-bottom: 30px;
    }
    .tips {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8087a0;
      line-height: 17px;
      margin-top: 10px;
    }
    .operatiop_wrap {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      background-color: #fff;
      .sele {
        width: 216px;
        margin-right: 20px;
      }
      .w226 {
        width: 226px;
        margin-right: 20px;
      }
      .all_bind {
        > span,
        > .el-checkbox {
          margin-left: 20px;
          margin-right: 0;
        }
      }
    }
    .table_wrap {
      max-height: 234px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #eff2f6;
      overflow-y: auto;
      box-sizing: border-box;
      color: #464b5c;
      .title_wrap {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .push_content {
        display: flex;
        align-items: center;
        &.de_style {
          // align-items: flex-start;
        }
        /deep/.el-checkbox {
          min-width: 100px;
        }
        .status {
          margin-left: 5px;
          &.retry_bind {
            cursor: pointer;
          }
        }
      }
      .operatiop_content {
        cursor: pointer;
      }
    }
  }
}
.el-popover .el-button {
  display: initial;
  margin-bottom: 0px;
  margin-left: 10px;
}
</style>
<style lang="scss" scoped>
.table_wrap {
  /*滚动条整体样式*/
  &::-webkit-scrollbar {
    /*高宽分别对应横竖滚动条的尺寸*/
    width: 5px;
    background: #eff2f6;
    border-radius: 4px;
  }
  /*滚动条里面滑块*/
  &::-webkit-scrollbar-thumb {
    background: #9fa4b7;
    opacity: 0.4;
    border-radius: 4px;
  }

  // 上箭头
  &::-webkit-scrollbar-button:vertical:single-button:start {
    display: none;
  }
  // 下箭头
  &::-webkit-scrollbar-button:vertical:single-button:end {
    display: none;
  }
}
</style>
<style lang="scss" scoped>
/deep/.el-table__header-wrapper {
  display: none;
}
/deep/.el-tabs__nav-scroll {
  padding-left: 15px;
}
/* .el-tabs__nav{
    height: 63px;
  } */
/deep/.el-tabs__nav .is-active {
  color: #5278e2 !important;
}
/deep/.el-tabs__active-bar {
  height: 4px !important;
}
/deep/.el-tabs__item {
  color: #8087a0 !important;
  font-size: 16px !important;
  padding: 12px 30px 0 0 !important;
  font-weight: 600 !important;
  &:last-of-type {
    padding: 0 !important;
  }
}
/deep/.el-tabs__header {
  background-color: #fff !important;
  height: 64px !important;
  margin-bottom: 15px !important;
}
/deep/.el-tabs__nav {
  height: 64px;
  margin-left: 0 !important;
}
/deep/.el-tabs__content {
  margin-top: 20px;
  background: initial;
}
/deep/.el-tabs__nav-wrap {
  height: 64px !important;
  &::after {
    background-color: transparent !important;
  }
}
// /deep/.el-table td.el-table__cell {
//   border-bottom: none;
// }
</style>