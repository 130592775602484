export default {
    marketingGuidance_00001: "写给运营商朋友",
    marketingGuidance_00002: "尊敬的运营商朋友，您好",
    marketingGuidance_00003: "我们深知充电站的营销是一件很不容易的事。路途虽长且不易，但您以非凡的毅力一直前行。智充，作为您坚实的后盾，始终与您并肩前行。",
    marketingGuidance_00004: "我们祝愿，通过这一功能，您将能拥有更加清晰的营销思路，掌握高效实用的策略方法，让那些曾经困扰您的充电站营销难题，逐一迎刃而解。",
    marketingGuidance_00005: "如何获取流量？",
    marketingGuidance_00006: "充电站开放引入第三方流量。<br>优惠活动组合。<br>场站有稳定的车队大客户。<br>裂变获客-邀请好友。",
    marketingGuidance_00007: "如何短时间内增加现金流？",
    marketingGuidance_00008: "大额储值送返活动。<br>第三方渠道售卖卡券。<br>售卖特权会员。",
    marketingGuidance_00009: "如何让车主常来充电？",
    marketingGuidance_00010: "用户精准分层分类营销。<br>设置用户升级保级成长体系。",
    marketingGuidance_00011: "使用引导",

}



