export default {
    work_00001: 'Initiate a Ticket',
    work_00002: "Please enter your nickname, mobile phone number, card number and multiple query criteria, which can be separated by comma (,), line feed, space and stop sign (,)",
    work_00003: 'Initiate',
    work_00004: 'Issue Station',
    work_00005: 'Site Name',
    work_00006: 'Issue Source',
    work_00007: 'Employee Reported',
    work_00008: 'Customer Reported',
    work_00009: 'Equipment Reported',
    work_00010: 'Other',
    work_00011: 'Ticket Title',
    work_00012: 'Related Charger',
    work_00013: 'Charger detail',
    work_00014: 'Charging order detail',
    work_00015: 'Priority',
    work_00016: 'Normal',
    work_00017: 'Urgent',
    work_00018: 'Very Urgent',
    work_00019: 'Issue Description',
    work_00020: 'Processor',
    work_00021: 'Follower',
    work_00022: 'Add User Relate to Ticket',
    work_00023: 'Clear',
    work_00024: 'Search Result',
    work_00025: 'Added',
    work_00026: 'Add',
    work_00027: 'Please choose the following',
    work_00028: 'Please select the station that is having issue',
    work_00029: 'Please select a priority',
    work_00030: 'Please select who reported the issue',
    work_00031: 'Please enter the title of the ticket',
    work_00032: 'Please enter a description of the issue',
    work_00033: 'Please select the respondent of the issue',
    work_00034: 'Charger Manufacturer',
    work_00035: 'Charging order detail',
    work_00036: 'Charger detail',
    work_00037: 'Stationsdetails',
    work_00038: 'All',
    work_00039: 'Charger is offline',
    work_00040: 'Equipment failure',
    work_00041: 'Charging amount is less than 1 kWh',
    work_00042: 'Charging duarion is too long',
    work_00043: 'Device malfunction',
    work_00044: 'Car malfunction',
    work_00045: 'Seriennummer des laufwerks eingeben',
    work_00046: 'Gib die bestellnummer ein.',

    work_10000: 'maintenance',
    work_10001: 'Select Charger',
    work_10002: 'Please select a certain device in advance. Problems and fault information will be generated automatically. The content will be replaced after changing the device. Please copy down the content at first if you would like to keep it.',
    work_10003: 'All Model',
    work_10004: 'SN',
    work_10005: 'Station',
    work_10006: 'CPO',
    work_10007: 'Another Charger',
    work_10008: 'Add a issue',
    work_10009: 'Station Address',
    work_10010: 'Admission Notice',
    work_10011: 'Related Transaction',
    work_10012: 'Select Transaction',
    work_10013: 'Error Information',
    work_10014: 'Error Code',
    work_10015: 'Stations',
    work_10016: 'Transactions ID',
    work_10017: 'Please add a issue',
    work_10018: 'Please select a charger',

    bill_00023: "Reset",
    bill_00024: "Search",
    bill_00025: "Revenue ($)",
    bill_00026: "Order amount({0})",
    bill_00027: "Electricity fee amount ({0})",
    bill_00028: "Service fee amount({0})",
    bill_00029: "Idle fee amount({0})",
    bill_00030: "Electricity Sold (kWh)",
    bill_00031: "Avg Charging Duration (min)",
    bill_00032: "Total Sessions",
    bill_00033: "Charger",
    bill_00034: "kWh",
    bill_00035: "Duration",
    bill_00036: "day",
    bill_00037: "h",
    bill_00038: "min",
    bill_00039: "Details",
    bill_00040: "App user",
    bill_00041: "Paid",
    bill_00042: "Paid",
    bill_00043: "Charging",
    bill_00044: "Billing",
    bill_00045: "To be paid",
    bill_00046: "paused",
    bill_00047: "Finished but still plugged",
    bill_00048: "Billing idle fee",
    bill_00049: "Cancelled",
    bill_00050: "Created",
    bill_00051: "Abnormal",
    bill_00052: "Start Time",
    bill_00053: "End Time",
    bill_00054: "Fleet",
    bill_00055: "Current bill amount",
    bill_00056: "USD",
    bill_00057: "Date from",
    bill_00058: "Date to",
    bill_00059: "Today",
    bill_00060: "A week ago",
    bill_00061: "15 days ago",
    bill_00062: "Reminder",
    bill_00063: "This will download Excel files of bills, continue?",
    bill_00064: "Confirm",
    bill_00065: "Cancel",
    bill_00066: "Data is in preparation, please wait patiently. Please do not close or refresh the page during this period...",
    bill_00067: "User nickname",
    bill_00068: "Telephone",
    bill_00069: "Card number",
    bill_00070: "License Plate Number",
    bill_00071: "Charging amount is less than 1 kWh",
    bill_00072: "Charging duarion is too long",
    bill_00073: "Device malfunction",
    bill_00074: "Car malfunction",
    bill_00075: "Double gun order",
    bill_00076: "Occupation fee",
    bill_00077: "yesterday",
    bill_00078: "30 days ago",
    bill_00079: "Prepaidkarte",
    bill_00080: "Start/Stopp-Karte",
    bill_00081: "ID Card",
    bill_00082: "Administrator",
    bill_00083: "Backup data",
    bill_00084: "Bank Card",
    bill_00085: "Monetary unit",
    bill_10000: "All CPO",
    bill_00086: "Set the parameters correctly",
    bill_00101: "Charging amount is more than 1000 kWh",
    bill_00102: "Abnormal charging capacity”",

    detailDepot_00035: 'Real picture',
    detailDepot_00036: 'Address, street, and house name',
    detailDepot_00037: 'Search',
    detailDepot_00038: 'Name',
    detailDepot_00039: 'Detailed address',
    detailDepot_00040: 'Current position',
    detailDepot_00041: 'Cancel',
    detailDepot_00042: 'Use this location',
    detailDepot_00043: 'Please choose the coordinate of charging staion',
    detailDepot_00044: 'Position selected does not match the address',
    detailDepot_00045: "Notice",
    detailDepot_00046: "I see",
    detailDepot_00047: "Address cannot be found",
    detailDepot_00048: "An unknown error has occurred on the Google map",

}
