import Vue from 'vue'
import zh from "../i18n/zh-cn.js"
import en from "../i18n/en.js"
import de from "../i18n/de.js"
import constants from "../utils/constants";
import "@/assets/font/iconfont.css";
// 当前环境
const productArea = constants.product_area;
console.log('productName---',productArea)

let lang = window.localStorage.lang;
if (!lang) {
    let language = navigator.language.slice(0,2);
    if (language === "ch" || language === "en" || language === "de" || language === "es") {
        lang = language;
    } else {
        var product = productArea;
        if (product == "eu") {
            lang = "en";
        } else {
            lang = "zh";
        }
    }
}

const list ={
  'zh': zh,
  'en': en,
  'de': de,
}
const m = list[lang] || zh
const menu = [
  //平台首页
  {
    title: m.common_00015,
    icon: 'iconfont icon-zhuye1',
    router: "preview.html",
    permission: "siteInfo",
    active: '0',
    children:[]
  },
  //整体概览
  {
    title: m.common_00016,
    icon: 'iconfont icon-gailanbeifen',
    router: "analyzeBI.html",
    permission: "biInfo",
    active: '1',
    children:[]
  },
  // 场站地图
  {
    title: m.common_00324,
    icon: "iconfont icon-gailanbeifen",
    router: "depotMap.html",
    permission: "siteInfo",
    active: '13',
    children:[]
  },
  // 场站中心
  {
    title: m.common_00004,
    icon: 'iconfont icon-a-changzhanbeifen3',
    router: "managementDepot.html",
    permission: "siteInfo",
    active: '2',
    children:[
      {
        title: m.common_00017,
        icon: 'iconfont icon-a-changzhanbeifen3',
        router: "chargeMonitor.html",
        permission: "deviceMonitorChart",
        active: '2-0',
        children:[]
      },
      {
        title: m.common_50005,
        icon: 'iconfont icon-a-changzhanbeifen3',
        router: "managementDepot.html",
        permission: "siteInfo",
        active: '2-1',
        children:[
          {
            title: m.common_00304,
            router: "managementDepot.html",
            permission: "siteInfo",
            active: '2-1-1',
          },
          {
            title: m.common_00022,
            router: "createDepot.html",
            permission: "siteInfoAD",
            active: '2-1-0',
          },
        ]
      },
      {
        title: m.common_00023,
        icon: 'iconfont icon-a-changzhanbeifen3',
        router: "rates.html",
        permission: "dealerFeepolicyInfo",
        active: '2-2',
        children:[
          {
            title: m.common_00024,
            router: "rates.html",
            permission: "dealerFeepolicyInfo",
            active: '2-2-0',
          },
          {
            title: m.common_61143,
            router: "pushTask.html",
            permission: "feePolicyPushTaskInfo",
            active: '2-2-1',
          },
        ]
      },
      {
        title: m.common_00028,
        icon: 'iconfont icon-a-changzhanbeifen3',
        router: "deviceMonitor.html#eq",
        permission: "deviceMonitorList",
        active: '2-3',
        children:[
          {
            title: m.common_00028,
            router: "deviceMonitor.html#eq",
            permission: "deviceMonitorList",
            active: '2-3-1',
          },
          {
            title: m.common_63013,
            router: "addDevice.html",
            permission: "deviceInfo",
            active: '2-3-0',
          },
        ]
      },
      {
        title: m.common_00043,
        icon: 'iconfont icon-a-changzhanbeifen3',
        router: "joinOperator.html",
        permission: "inviteCodeInfo",
        active: '2-4',
        children:[]
      }
    ]
  },
  // 订单中心
  {
    title: m.common_00005,
    icon: 'iconfont icon-a-dingdanbeifen4',
    router: "bill.html",
    permission: "chargeBillInfo",
    active: '3',
    children: [
      {
        title: m.common_00049,
        icon: 'iconfont icon-bianzu',
        router: "bill.html",
        permission: "chargeBillInfo",
        active: '3-0',
        children:[]
      },
      {
        title: m.common_00105,
        icon: '',
        router: "cardSales.html",
        permission: "discountPackageInfo",
        active: '3-1',      
        children: []
      },
      {
        title: m.common_00051,
        icon: '',
        router: "occupancyQuery.html",
        permission: "occupyBillInfo",
        active: '3-2',      
        children: []
      },
    ]
  },
  // 维保中心
  {
    title: m.common_10004,
    icon: 'iconfont icon-bianzu',
    router: "maintenanceHome.html",
    permission: "DeviceErrorLogInfo",
    active: '4',
    children: [
      {
        title: m.common_10006,
        icon: 'iconfont icon-bianzu',
        router: "maintenanceHome.html",
        permission: "DeviceErrorLogInfo",
        active: '4-0',
        children:[]
      },
      {
        title: m.common_00061,
        icon: '',
        router: "deviceMonitor.html#maint",
        permission: "deviceMonitorList",
        active: '4-1',      
        children: []
      },
      {
        title: m.common_00307,
        icon: 'iconfont icon-a-changzhanbeifen3',
        router: "powerMaint.html#power",
        permission: "DeviceLifeStatsInfo",
        active: '4-2',
        children:[
          {
            title: m.common_00062,
            router: "powerMaint.html#power",
            permission: "DeviceLifeStatsInfo",
            active: '4-2-0',
          },
          {
            title: m.common_40000,
            router: "powerMaint.html#eq",
            permission: "DeviceLifeStatsInfo",
            active: '4-2-1',
          },
        ]
      },
      {
        title: m.common_31007,
        icon: '',
        router: "autoNotice.html",
        permission: "errorWarnInfo",
        active: '4-3',      
        children: []
      },
      {
        title: m.common_31000,
        icon: 'iconfont icon-a-changzhanbeifen3',
        router: "orderManagement.html",
        permission: "workFlowInfo",
        active: '4-4',
        children:[
          {
            title: m.common_31001,
            router: "orderManagement.html",
            permission: "workFlowInfo",
            active: '4-4-0',
          },
          {
            title: m.common_41001,
            router: "orderTriggerList.html",
            permission: "workFlowInfo",
            active: '4-4-1',
          },
        ]
      },
      {
        title: m.common_00056,
        icon: 'iconfont icon-a-changzhanbeifen3',
        router: "errorLog.html",
        permission: "DeviceErrorLogInfo",
        active: '4-5',
        children:[
          {
            title: m.common_00057,
            router: "errorLog.html",
            permission: "DeviceErrorLogInfo",
            active: '4-5-0',
          },
          {
            title: m.common_00058,
            router: "vehicleChargingLog.html",
            permission: "VehicleChargeLogInfo",
            active: '4-5-1',
          },
          {
            title: m.common_00059,
            router: "platformLog.html",
            permission: "DeviceCommLogInfo",
            active: '4-5-2',
          },
          {
            title: m.common_61079,
            router: "chargingFail.html",
            permission: "chargeBillInfo",
            active: '4-5-3',
          },
        ]
      },
      {
        title: m.common_31004,
        icon: 'iconfont icon-a-changzhanbeifen3',
        router: "knowledge.html",
        permission: "workFlowInfo",
        active: '4-6',
        children:[]
      },
    ]
  },
  // 安全中心
  {
    title: m.common_00007,
    icon: 'iconfont icon-a-bianzu14beifen3',
    router: "securityRealTimeProtection.html",
    permission: "securityFenceInfo",
    active: '5',
    children:[
      /* {
        title: "安全首页",
        icon: 'iconfont icon-bianzu',
        router: "securityRealTimeProtection.html",
        permission: "securityFenceInfo",
        active: '5-0',
        children:[]
      }, */
      {
        title: m.common_61100,
        icon: 'iconfont icon-bianzu',
        router: "securityRealTimeProtection.html",
        permission: "securityFenceInfo",
        active: '5-1',
        children:[]
      },
      {
        title: m.common_61101,
        icon: 'iconfont icon-bianzu',
        router: "safetyLog.html",
        permission: "securityFenceInfo",
        active: '5-2',
        children:[]
      },
      {
        title: m.common_61102,
        icon: 'iconfont icon-bianzu',
        router: "safetyReport.html",
        permission: "securityFenceInfo",
        active: '5-3',
        children:[]
      },
      {
        title: m.common_00063,
        icon: 'iconfont icon-a-changzhanbeifen3',
        router: "safetyFence.html",
        permission: "securityFenceInfo",
        active: '5-4',
        children:[
          {
            title: m.common_80003,
            router: "safetyFence.html",
            permission: "securityFenceInfo",
            active: '5-4-0',
          },
          {
            title: m.common_61103,
            router: "limitedCharge.html",
            permission: "securityFenceInfo",
            active: '5-4-1',
          },
          {
            title: m.common_61162,
            router: "securityLimited.html",
            permission: "securityFenceInfo",
            active: '5-4-2',
          },
        ]
      },
    ]
  },
  // 用户中心
  {
    title: m.common_00008,
    icon: 'iconfont icon-a-bianzu15beifen5',
    router: "user.html",
    permission: "wechatUserInfo",
    active: '6',
    children:[
      /* {
        title: "用户概览",
        icon: 'iconfont icon-bianzu',
        router: "group.html",
        permission: "userGroupInfo",
        active: '6-0',
        children:[]
      }, */
      {
        title: m.common_00211,
        icon: 'iconfont icon-bianzu',
        active: '6-1',
        children:[
          {
            title: m.common_00069,
            router: "user.html",
            permission: "wechatUserInfo",
            active: '6-1-0',
          },
          {
            title: m.common_80018,
            router: "userRanking.html",
            permission: "biUserRankInfo",
            active: '6-1-1',
          },
          {
            title: m.common_00070,
            router: "userClassification.html",
            permission: "userClassInfo",
            active: '6-1-2',
          },
          {
            title: m.common_00270,
            router: "blackList.html",
            permission: "blacklistInfo",
            active: '6-1-3',
          },
        ]
      },
      {
        title: m.common_00086,
        icon: 'iconfont icon-bianzu',
        active: '6-2',
        children:[
          {
            title: m.common_00087,
            router: "member.html",
            permission: "userGradeInfo",
            active: '6-2-0',
          },
          {
            title: m.common_62000,
            router: "privilegedMember.html",
            permission: "userGradeInfo",
            active: '6-2-1',
          },
        ]
      },
      {
        title: m.common_71018,
        icon: 'iconfont icon-bianzu',
        router: "group.html",
        permission: "userGroupInfo",
        active: '6-3',
        children:[]
      },
      {
        title: m.common_00076,
        icon: 'iconfont icon-bianzu',
        router: "chargeCard.html",
        permission: "nfcgroupInfo",
        active: '6-4',
        children:[
          {
            title: m.common_00077,
            router: "chargeCard.html",
            permission: "nfcgroupInfo",
            active: '6-4-0',
          },
          {
            title: m.common_71019,
            router: "managementCharger.html#U3",
            permission: "nfcgroupInfo",
            active: '6-4-1',
          },
        ]
      },
      {
        title: m.common_00083,
        icon: 'iconfont icon-bianzu',
        router: "car.html",
        permission: "vehicleInfo",
        active: '6-5',
        children:[]
      },
      {
        title: m.common_00084,
        icon: 'iconfont icon-bianzu',
        router: "feedback.html",
        permission: "wechatFeedbackInfo",
        active: '6-6',
        children:[]
      },
    ]
  },
  // 营销中心
  {
    title: m.common_00009,
    icon: 'iconfont icon-a-bianzu17beifen3',
    router: "activityOverview.html",
    permission: "promotionInfo",
    active: '7',
    children:[
      /* {
        title: "营销首页",
        icon: 'iconfont icon-bianzu',
        router: "coupon.html#dy",
        permission: "couponTemplateInfo",
        active: '7-0',
        children:[]
      }, */
      {
        title: m.common_72000,
        icon: 'iconfont icon-bianzu',
        router: "marketingGuidance.html",
        permission: "couponTemplateInfo",
        active: '7-0',
        children: []
      },
      {
        title: m.common_00088,
        icon: "el-icon-document",
        active: '7-1',
        children: [
          {
            title: m.common_00089,
            router: "coupon.html#dy",
            permission: "couponTemplateInfo",
            active: '7-1-0',
          },
          {
            title: m.common_00090,
            router: "coupon.html#zk",
            permission: "couponTemplateInfo",
            active: '7-1-1',
          },
          {
            title: m.common_00091,
            router: "cashCoupon.html#dj",
            permission: "discountPackageInfo",
            active: '7-1-2',
          },
          {
            title: m.common_00092,
            router: "cashCoupon.html#dl",
            permission: "discountPackageInfo",
            active: '7-1-3',
          },
        ],
      },
      {
        title: m.common_00094,
        icon: "el-icon-document",
        active: '7-2',
        children: [
          {
            title: m.common_20009,
            router: "activityOverview.html",
            permission: "promotionInfo",
            active: '7-2-0',
          },
          {
            title: m.common_00095,
            router: "storedValueActivity.html#value",
            permission: "promotionInfo",
            active: '7-2-1',
          },
          {
            title: m.common_00096,
            router: "storedValueActivity.html#free",
            permission: "promotionInfo",
            active: '7-2-2',
          },
          {
            title: m.common_00098,
            router: "storedValueActivity.html#charge",
            permission: "promotionInfo",
            active: '7-2-3',
          },
          {
            title: m.common_80001,
            router: "teamPlaylistActivity.html",
            permission: "teamRankingInfo",
            active: '7-2-4',
          }
        ],
      },
      {
        title: m.common_00102,
        icon: 'iconfont icon-bianzu',
        router: "inviteFriends.html",
        permission: "inviteFriendInfo",
        active: '7-6',
        children:[]
      },
      {
        title: m.common_00104,
        icon: "el-icon-document",
        router: "chargingPackage.html",
        permission: "discountPackageInfo",
        active: '7-3',
        children: []
      },
      {
        title: m.common_00107,
        icon: "el-icon-document",
        active: '7-4',
        children: [
          {
            title: m.common_00108,
            router: "convertCode.html",
            permission: "discountPackageInfo",
            active: '7-4-0',
          },
          {
            title: m.common_00109,
            router: "convertCodeRecord.html",
            permission: "discountPackageInfo",
            active: '7-4-1',
          },
        ],
      },
      {
        title: m.common_46000,
        icon: "el-icon-document",
        active: '7-5',
        children: [
          {
            title: m.common_46001,
            router: "minProgramBanner.html",
            permission: "wechatAppBannerInfo",
            active: '7-5-0',
          },
          {
            title: m.common_46002,
            router: "eventRelease.html",
            permission: "wechatAppPromotionInfo",
            active: '7-5-1',
          },
          {
            title: m.common_46003,
            router: "followOfficialAccount.html",
            permission: "wechatAppFollowInfo",
            active: '7-5-2',
          }, 
        ],
      },
    ]
  },
  // 数据中心
  {
    title: m.common_00010,
    icon: 'iconfont icon-a-bianzu18beifen5',
    router: "analyzeXcharge.html#whole",
    permission: "biInfo",
    active: '8',
    children:[
      {
        title: m.common_61146,
        icon: 'iconfont icon-bianzu',
        router: "report.html#week",
        permission: "biWeekDataAnalysis",
        active: '8-0',
        children:[]
      },
      {
        title: m.common_00111,
        icon: "el-icon-document",
        active: '8-1',
        children: [
          {
            title: m.common_00112,
            router: "analyzeXcharge.html#whole",
            permission: "biInfo",
            active: '8-1-0',
          },
          {
            title: m.common_00113,
            router: "analyzeXcharge.html#site",
            permission: "biInfo",
            active: '8-1-1',
          },
          {
            title: m.common_00114,
            router: "analyzeXcharge.html#device",
            permission: "biInfo",
            active: '8-1-2',
          },
          {
            title: m.common_00115,
            router: "analyzeXcharge.html#date",
            permission: "biInfo",
            active: '8-1-3',
          },
          {
            title: m.common_00116,
            router: "analyzeXcharge.html#average",
            permission: "biInfo",
            active: '8-1-4',
          },
          {
            title: m.common_00117,
            router: "analyzeXcharge.html#operator",
            permission: "biInfo&&inviteCodeInfo",
            active: '8-1-5',
          },
        ],
      },
      {
        title: m.common_00118,
        icon: "el-icon-document",
        active: '8-2',
        children: [
          {
            title: m.common_00119,
            router: "analyzeCharge.html#whole",
            permission: "biInfo",
            active: '8-2-0',
          },
          {
            title: m.common_00120,
            router: "analyzeCharge.html#site",
            permission: "biInfo",
            active: '8-2-1',
          },
          {
            title: m.common_00121,
            router: "analyzeCharge.html#group",
            permission: "biInfo&&userGroupInfo",
            active: '8-2-2',
          },
          {
            title: m.common_00122,
            router: "analyzeCharge.html#user",
            permission: "biInfo",
            active: '8-2-3',
          },
          {
            title: m.common_00123,
            router: "analyzeCharge.html#operator",
            permission: "biInfo&&inviteCodeInfo",
            active: '8-2-4',
          },
        ],
      },
      {
        title: m.common_00124,
        icon: "el-icon-document",
        active: '8-3',
        children: [
          {
            title: m.common_00125,
            router: "analyzeIncome.html#whole",
            permission: "biInfo",
            active: '8-3-0',
          },
          {
            title: m.common_00126,
            router: "analyzeIncome.html#site",
            permission: "biInfo",
            active: '8-3-1',
          },
          {
            title: m.common_00127,
            router: "analyzeIncome.html#group",
            permission: "biInfo&&userGroupInfo",
            active: '8-3-2',
          },
          {
            title: m.common_00129,
            router: "analyzeIncome.html#user",
            permission: "biInfo",
            active: '8-3-3',
          },
          {
            title: m.common_00130,
            router: "analyzeIncome.html#operator",
            permission: "biInfo&&inviteCodeInfo",
            active: '8-3-4',
          },
        ],
      },
      {
        title: m.common_00132,
        icon: "el-icon-document",
        active: '8-4',
        children: [
          {
            title: m.common_00133,
            router: "analyzeUser.html#whole",
            permission: "biInfo",
            active: '8-4-0',
          },
          {
            title: m.common_00134,
            router: "analyzeUser.html#site",
            permission: "biInfo",
            active: '8-4-1',
          },
          {
            title: m.common_00135,
            router: "analyzeUser.html#user",
            permission: "biInfo",
            active: '8-4-2',
          },
          {
            title: m.common_00136,
            router: "analyzeUser.html#contribution",
            permission: "biInfo",
            active: '8-4-3',
          },
          {
            title: m.common_00137,
            router: "analyzeUser.html#rfm",
            permission: "biInfo",
            active: '8-4-4',
          },
          {
            title: m.common_00138,
            router: "analyzeUser.html#operator",
            permission: "biInfo&&inviteCodeInfo",
            active: '8-4-5',
          },
        ],
      },
      {
        title: m.common_00366,
        router: "cardAnalysis.html#card",
        permission: "couponTemplateInfo",
        active: '8-5',
        children: [],
      },
    ]
  },
  // 财务中心
  {
    title: m.common_00011,
    icon: 'iconfont icon-caiwu',
    router: "financialHome.html",
    permission: "invoiceInfo",
    active: '9',
    children:[
      {
        title: m.common_71020,
        icon: 'iconfont icon-bianzu',
        router: "financialHome.html",
        permission: "invoiceInfo",
        active: '9-0',
        children:[]
      },
      {
        title: m.common_00152,
        router: "personalInvoiceApplication.html",
        permission: "invoiceInfo",
        active: '9-1',
        children:[]
      },
      {
        title: m.common_00153,
        router: "groupInvoiceApplication.html",
        permission: "userGroupInfo",
        active: '9-2',
        children:[]
      },
      {
        title: m.common_00154,
        router: "walletRefundAccept.html",
        permission: "refundInfo",
        active: '9-3',
        children:[]
      },
      {
        title: m.common_00156,
        icon: "el-icon-document",
        active: '9-4',
        children: [
          {
            title: m.common_00157,
            router: "monthlyStatistics.html#recharge",
            permission: "settleInfo",
            active: '9-4-0',
          },
          {
            title: m.common_00158,
            router: "monthlyStatistics.html#refund",
            permission: "refundInfo",
            active: '9-4-1',
          },
          {
            title: m.common_00159,
            router: "monthlyStatistics.html#wallet",
            permission: "settleInfo",
            active: '9-4-2',
          },
          {
            title: m.common_00160,
            router: "monthlyStatistics.html#shop",
            permission: "discountPackageInfo",
            active: '9-4-3',
          },
        ],
      },
      {
        title: m.common_00161,
        router: "financingSettings.html#userRechargeSettings",
        permission: "allChannel",
        active: '9-5',
        children: [],
      },
      {
        title: m.common_00168,
        icon: "el-icon-document",
        active: '9-6',
        children: [
          {
            title: m.common_45009,
            router: "selfHelpPayCost.html#service",
            permission: "dealerAccountInfo",
            active: '9-6-0',
          },
          {
            title: m.common_00169,
            router: "selfHelpPayCost.html#platformBillings",
            permission: "dealerAccountInfo",
            active: '9-6-1',
          },
          {
            title: m.common_00170,
            router: "selfHelpPayCost.html#accountManagement",
            permission: "dealerAccountInfo",
            active: '9-6-2',
          },
        ],
      },
    ]
  },
  // 流量中心
  {
    title: m.common_00012,
    icon: 'iconfont icon-liuliang1',
    router: "diversionServices.html#4",
    permission: "outsideOwnerInfo",
    active: '10',
    children: [
      /* {
        title: "流量总览",
        icon: 'iconfont icon-bianzu',
        router: "diversionServices.html#4",
        permission: "outsideOwnerInfo",
        active: '10-0',
        children:[]
      }, */
      /* {
        title: m.common_00174,
        icon: 'iconfont icon-bianzu',
        active: '10-1',
        children:[ */
          {
            title: m.common_00175,
            router: "diversionServices.html#4",
            permission: "outsideOwnerInfo",
            active: '10-1-0',
            children: []
          },
          {
            title: m.common_00176,
            router: "diversionServices.html#1",
            permission: "outsideOwnerInfo",
            active: '10-1-1',
            children: []
          },
          {
            title: m.common_00177,
            router: "diversionServices.html#5",
            permission: "outsideOwnerInfo",
            active: '10-1-2',
            children: []
          },
          {
            title: m.common_00178,
            router: "diversionServices.html#2",
            permission: "outsideOwnerInfo",
            active: '10-1-3',
            children: []
          },
          {
            title: m.common_00318,
            router: "diversionServices.html#6",
            permission: "outsideOwnerInfo",
            active: '10-1-4',
            children: []
          },
      /*   ]
      }, */
    ]
  },
  // 能源中心
  {
    title: m.common_20016,
    icon: 'iconfont icon-nengyuan',
    router: "energyHome.html",
    permission: "energyStorageInfo",
    active: '11',
    children:[
      /* {
        title: "能源概览",
        icon: 'iconfont icon-bianzu',
        router: "energyHome.html",
        permission: "energyStorageInfo",
        active: '11-0',
        children:[]
      }, */
      /* {
        title: "场站监控",
        icon: "el-icon-document",
        active: '11-1',
        children: [ */
          {
            title: m.common_60010,
            router: "energyHome.html",
            permission: "energyStorageInfo",
            active: '11-1-0',
            children: []
          },
          {
            title: m.common_20010,
            router: "energyOperationMonitoring.html",
            permission: "energyStorageInfo",
            active: '11-1-1',
            children: []
          },
          {
            title: m.common_20011,
            router: "revenueMonitoring.html",
            permission: "energyStorageInfo",
            active: '11-1-2',
            children: []
          },
          {
            title: m.common_61073,
            router: "workSchedule.html",
            permission: "energyStorageInfo",
            active: '11-1-3',
            children: []
          },
      /*   ],
      }, */
    ]
  },
  // 系统中心
  {
    title: m.common_00013,
    icon: 'iconfont icon-a-bianzu29beifen5',
    router: "systemInfoSetting.html",
    permission: "dealerInfo",
    active: '12',
    children:[
      {
        title: m.common_00187,
        icon: 'iconfont icon-bianzu',
        router: "systemInfoSetting.html",
        permission: "dealerInfo",
        active: '12-0',
        children:[]
      },
      {
        title: m.common_00182,
        icon: 'iconfont icon-bianzu',
        router: "accountManage.html",
        permission: "employeeInfo",
        active: '12-1',
        children:[]
      },
      {
        title: m.common_00184,
        icon: "el-icon-document",
        active: '12-2',
        children: [
          {
            title: m.common_00190,
            router: "weChatApp.html",
            permission: "wechatoaInfo",
            active: '12-2-0',
          },
          {
            title: m.common_00191,
            router: "alipayApp.html",
            permission: "alipayInfo",
            active: '12-2-1',
          },
          {
            title: m.common_00192,
            router: "deviceMessageTemplate.html",
            permission: "dealerInfo",
            active: '12-2-2',
          },
          {
            title: m.common_00185,
            router: "https://www.zhichongkeji.com/OpenAPI/",
            permission: "dealerInfo",
            active: '12-2-3',
          },
        ],
      },
    ]
  },
]

export default menu