export default {
    chargingFail_00001: "用户昵称",
    chargingFail_00002: "卡号",
    chargingFail_00003: "创建时间",
    chargingFail_00004: "失败原因",
    chargingFail_00005: "已取消订单编号",
    chargingFail_00006: "订单用户",
    chargingFail_00007: "请选择时间范围",
    chargingFail_00008: "",
    chargingFail_00009: "",
    chargingFail_00010: "",
    chargingFail_00011: "",
    chargingFail_00012: "",
    chargingFail_00013: "",
    chargingFail_00014: "",
    chargingFail_00015: "",
    chargingFail_00016: "",
    chargingFail_00017: "",
    chargingFail_00018: "",
    chargingFail_00019: "",
    chargingFail_00020: "",
};