export default {
    bill_00001: "订单中心",
    bill_00002: "全部充电订单",
    bill_00003: "订单状态",
    bill_00004: "全部",
    bill_00005: "充电中",
    bill_00006: "待支付",
    bill_00007: "已支付/第三方支付",
    bill_00008: "异常",
    bill_00009: "用户类型",
    bill_00010: "微信用户",
    bill_00011: "在线身份卡",
    bill_00012: "离线储值卡",
    bill_00013: "离线启停卡",
    bill_00014: "系统管理员",
    bill_00015: "团体",
    bill_00016: "第三方用户",
    bill_00017: "订单列表",
    bill_00018: "导出订单",
    bill_00019: "订单编号",
    bill_00020: "订单用户",
    bill_00021: "请输入用户名称",
    bill_00022: "站点设备",
    bill_00023: "重置",
    bill_00024: "查询",
    bill_00025: "收入({0})",
    bill_00026: "订单金额({0})",
    bill_00027: "电费金额({0})",
    bill_00028: "服务费金额({0})",
    bill_00029: "延时费金额({0})",
    bill_00030: "充电电量({0})",
    bill_00031: "充电时间(分钟)",
    bill_00032: "充电次数",
    bill_00033: "充电口",
    bill_00034: "已充电量({0})",
    bill_00035: "充电时长",
    bill_00036: "天",
    bill_00037: "小时",
    bill_00038: "分钟",
    bill_00039: "订单详情",
    bill_00040: "微信用户",
    bill_00041: "已支付",
    bill_00042: "第三方支付",
    bill_00043: "充电中",
    bill_00044: "计费中",
    bill_00045: "待支付",
    bill_00046: "已暂停",
    bill_00047: "结束未拔枪",
    bill_00048: "延误计费中",
    bill_00049: "已取消",
    bill_00050: "已创建",
    bill_00051: "异常",
    bill_00052: "开始充电",
    bill_00053: "结束充电",
    bill_00054: "团体",
    bill_00055: "当前订单金额",
    bill_00056: "实际支付金额",
    bill_00057: "开始日期",
    bill_00058: "结束日期",
    bill_00059: "今天",
    bill_00060: "7天前",
    bill_00061: "15天前",
    bill_00062: "提示",
    bill_00063: "此操作将下载账单的Excel文件, 是否继续?",
    bill_00064: "确定",
    bill_00065: "取消",
    bill_00066: "数据正在准备中，请耐心等待。当数据准备完后会自动关闭该弹窗并开始下载，在此期间请您不要关闭或刷新页面...",
    bill_00067: "用户昵称",
    bill_00068: "手机号",
    bill_00069: "卡号",
    bill_00070: "车牌号",
    bill_00071: "电量小于1度",
    bill_00072: "充电时间超长",
    bill_00073: "设备异常结束",
    bill_00074: "车辆异常结束",
    bill_00075: "双枪订单",
    bill_00076: "占用费",
    bill_00077: "昨天",
    bill_00078: "30天前",
    bill_00079: "储值卡",
    bill_00080: "启停卡",
    bill_00081: "身份卡",
    bill_00082: "管理员",
    bill_00083: "备份数据",
    bill_00084: "POS机用户",
    bill_00085: "货币单位",
    bill_00086: "订单用户",
    bill_00087: "用户昵称",
    bill_00088: "手机号",
    bill_00089: "卡号",
    bill_00090: "车牌号",
    bill_00091: "即插即充",
    bill_00100: "您确定导出订单？点击确定，平台将为您准备数据。数据准备完成后（一般10分钟以内完成），如果您停留在页面，会自动下载订单文件，如果您离开页面，可在平台顶部通知里点击下载文件。导出的订单文件将为您保留7天，请您及时下载。过期后，可重新导出订单。导出订单的文件为Excel文件。若订单数据量过大可能会导致下载超时或失败，如果下载失败请尝试缩短时间范围。",
    bill_00101: "电量大于1000度",
    bill_00102: "平均充电功率超出该型号设备的最大输出充电功率",
    bill_00103: "订单改价金额({0})",
    bill_00104: "优惠/抵扣金额({0})",
    bill_00105: "历史订单下载",
    bill_00106: "订单时间",
    bill_00107: "年份",
    bill_00108: "订单用户",
    bill_00109: "充电场站",
    bill_00110: "下载",
    bill_00111: "请选择完整的时间范围",
    bill_00112: "",
    bill_00113: "",
}