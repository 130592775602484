
export default {
  carOwnerCharging_00001: "车主常来充电",
  carOwnerCharging_00002: "引导目录",
  carOwnerCharging_00003: "用户精准分类营销",
  carOwnerCharging_00004: "用户成长等级会员",
  carOwnerCharging_00005: "1、建议您使用“用户成长等级会员”功能，通过成长等级会员体系能给用户逐渐带来成就感和身份优越感。",
  carOwnerCharging_00006: "2、“会员等级”任务设置时建议您参考游戏等级设置思路，先易后难。特别是初期的低等级升级任务要尽可能容易些。",
  carOwnerCharging_00007: "2.1、建议您设置升级任务时，同时开启保级任务。",
  carOwnerCharging_00008: '2.2、“升级/保级任务类型”建议您使用充电量。',
  carOwnerCharging_00009: "3、“会员权益”设置，",
  carOwnerCharging_00010: '1.2、“储值金额退款”建议您设置”不支持退款“。',
  carOwnerCharging_00011: '1.3、“参与活动用户范围”建议您设置“不限制”。',
  carOwnerCharging_00012: '2、活动建好后，微信公众号等车主客户端活动充值要设置为开启。',
  carOwnerCharging_00013: '3、活动建好后，建议您加大宣传推广，灵活运用“活动外发专用链接”。',
  carOwnerCharging_00014: "3.1、建议您高等级会员权益要明显大于低等级会员权益。",
  carOwnerCharging_00015: "3.2、建议您设置完成任务奖励。特别是针对低等级会员的完成任务奖励。",
  carOwnerCharging_00016: "去设置成长等级会员",
  carOwnerCharging_00017: "想要车主常来充电，需要对车主持续地进行精细化分类营销。",
  carOwnerCharging_00018: "用户精准分类",
  carOwnerCharging_00019: "1、使用“用户分类”功能时，建议您关注查看“参考依据”数据。",
  carOwnerCharging_00020: "2、建议您重视区域分类条件，特别是通过市-区-场站分门别类的建立分层分类用户。",
  carOwnerCharging_00021: "3、新能源汽车充电行业RFM模型应用：",
  carOwnerCharging_00022: "通过用户分类可使用RFM模型来找到目标用户。相对应模型",
  carOwnerCharging_00023: "R（最近一次充电订单支付时间）",
  carOwnerCharging_00024: "F（一段时间内单个用户非团体且已支付的充电订单累计订单数）",
  carOwnerCharging_00025: "M（一段时间内单个用户非团体且已支付的充电订单累计充电量）",
  carOwnerCharging_00026: "其中R、F是重要影响参数，M相对次之。",
  carOwnerCharging_00027: "高",
  carOwnerCharging_00028: "低",
  carOwnerCharging_00029: "不限制",
  carOwnerCharging_00030: "用户",
  carOwnerCharging_00031: "重要活跃",
  carOwnerCharging_00032: "需重点深耕",
  carOwnerCharging_00033: "需主动联系",
  carOwnerCharging_00034: "可尝试找回",
  carOwnerCharging_00035: "去设置用户分类",
  carOwnerCharging_00036: "优惠活动组合",
  carOwnerCharging_00037: "1、用户精准分类和多类型优惠活动相结合，对车主常来充电起到很好的效果。",
  carOwnerCharging_00038: "2、建议您根据您的需要，持续对分类用户进行优惠营销活动。",
  carOwnerCharging_00039: "3、一些类型用户营销建议",
  carOwnerCharging_00040: "对于重要活跃的用户，建议您可尝试多使用储值送返活动。",
  carOwnerCharging_00041: "对于需重点深耕的用户，建议您可尝试多频率的使用低门槛高优惠的，领取优惠券活动和充电送返活动。",
  carOwnerCharging_00042: "对于需主动联系的用户，建议您可尝试人工联系维护，可通过领取优惠券活动的“手动赠送”功能赠送免单券和免服务费券。",
  carOwnerCharging_00043: '4、活动建好后，建议您加大宣传推广，灵活运用"活动外发专用链接”。',
  carOwnerCharging_00044: '以下是可通过"活动外发专用链接"宣传推广案例，希望给您启发。',
  carOwnerCharging_00045: '可通过"活动外发专用链接"生成二维码，做成活动单，粘贴在场站入口等明显位置，供车主方便充值:',
  carOwnerCharging_00046: '可通过"活动外发专用链接"嵌入微信公众号文章内、自动回复-被关注回复等线上宣传渠道:',
  carOwnerCharging_00047: '可将"活动外发专用链接"发到自己的新能源车主微信群中;等等',
  carOwnerCharging_00048: "去设置储值送返活动",
  carOwnerCharging_00049: "去设置领取优惠券活动",
  carOwnerCharging_00050: "去设置充电送返活动",
  carOwnerCharging_00051: "建立私域车主微信群",
  carOwnerCharging_00052: "1、根据您的需要，建议您为重点的分类用户建立专门的私域车主微信群，更好的维护用户。",
  carOwnerCharging_00053: "2、各种优惠活动的外发链接和车主微信群结合，可以更好的留住用户。",
  carOwnerCharging_00054: "3、建议您注册使用企业微信，更具品牌效应。",
  carOwnerCharging_00055: "引导语",
  carOwnerCharging_00056: "",
  carOwnerCharging_00057: "",
  carOwnerCharging_00058: "",
  carOwnerCharging_00059: "现有方式售卖",
  carOwnerCharging_00060: '1、可通过“开通特权会员”功能在微信公众号等车主客户端售卖。',
  carOwnerCharging_00061: "2、灵活运用“特权会员买入”链接，多种宣传方式售卖。",
  carOwnerCharging_00062: '以下是可通过"特权会员买入"链接售卖案例，希望给您启发。',
  carOwnerCharging_00063: '可通过"特权会员买入"链接生成二维码，做成购买海报，粘贴在场站入口等明显位置，供车主购买:',
  carOwnerCharging_00064: '可通过"特权会员买入"链接嵌入公众号自定义菜单、微信公众号文章内等线上宣传渠道:',
  carOwnerCharging_00065: '可将"特权会员买入"链接发到自己的新能源车主微信群中;等等',
  carOwnerCharging_00066: "去设置特权会员上架售卖",
  carOwnerCharging_00067: "去查看特权会员销售订单",








}